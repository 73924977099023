<template>
  <v-data-table
    v-show="!loading"
    class="employees-overview-table"
    :headers="headers"
    :items="localizedTemplates"
    :items-per-page="50"
    :loading="loading"
    :loading-text="$t('dataLoading')"
    :fixed-header="true"
  >
    <template v-slot:item.title="{ item }">
      {{ item.localizedTitle }}
    </template>

    <template v-slot:item.processType="{ item }">
      {{ getProcessType(item) }}
    </template>

    <template v-slot:item.feedbackType="{ item }">
      {{ getFeedbackType(item) }}
    </template>

    <template v-slot:item.notificationType="{ item }">
      {{
        $t(`notificationTemplates.notificationType.${item.notificationType}`)
      }}
    </template>

    <template v-slot:item.recipientUser="{ item }">
      {{ $t(`notificationTemplates.recipient.${item.recipientUser}`) }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn
        small
        text
        :to="{ name: 'EditNotificationTemplate', params: { id: item.id } }"
      >
        <v-icon small> mdi-pencil </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    templates: {
      type: Array,
      required: true
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    localizedTemplates() {
      const templates = [...this.templates];
      templates.forEach((x) => (x.localizedTitle = this.localize(x.title)));
      return templates;
    },

    headers() {
      return [
        {
          text: 'Titel',
          sortable: true,
          value: 'localizedTitle'
        },
        {
          text: 'Prozess-Typ',
          sortable: true,
          value: 'processType'
        },
        {
          text: 'Feedback-Typ',
          sortable: true,
          value: 'feedbackType'
        },
        {
          text: 'Empfänger',
          sortable: true,
          value: 'recipientUser'
        },
        {
          text: 'Benachrichtigungsart',
          sortable: true,
          value: 'notificationType'
        },
        {
          text: 'Aktionen',
          sortable: false,
          value: 'actions'
        }
      ];
    }
  },

  methods: {
    getProcessType(item) {
      if (!item.processType) {
        return 'Alle';
      }

      return this.$t(`feedbacks.processTypes.${item.processType}`);
    },

    getFeedbackType(item) {
      if (!item.feedbackType) {
        return 'Alle';
      }

      return this.$t(`feedbacks.typeOptions.${item.feedbackType}`);
    }
  }
};
</script>
