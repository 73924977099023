<template>
  <v-card class="segmented" flat>
    <v-card-title>
      <v-row class="align-center">
        <v-col cols="6">
          <h5>{{ $t('notificationTemplates.breadcrumb') }}</h5>
        </v-col>
        <v-col cols="6" lg="4" offset-lg="2">
          <v-text-field
            v-model="keyword"
            solo
            flat
            hide-details
            :label="$t('searchKeywordPlaceholder')"
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <templates-list :templates="templates"></templates-list>
    </v-card-text>
  </v-card>
</template>

<script>
import templatesList from '@/components/NotificationTemplates/templates-table.vue';

import { mapActions } from 'vuex';

export default {
  data: () => {
    return {
      keyword: '',
      templates: []
    };
  },

  components: {
    templatesList
  },

  async mounted() {
    this.templates = await this.listTemplates();
  },

  methods: {
    ...mapActions({
      listTemplates: 'notifications/listTemplates'
    })
  }
};
</script>
