<template>
  <div>
    <tiptap-vuetify
      v-model="textInSelectedLanguage"
      :extensions="extensions"
      :placeholder="$t('emailDetails.body')"
      :disabled="disabled"
    >
      <template slot="footer" v-if="multiLang">
        <div class="text-right">
          <v-btn
            small
            class="mr-1"
            elevation="0"
            @click="setLanguage('de-DE')"
            :color="getColor('de-DE')"
          >
            <img src="@/assets/images/flags/de.png" />
          </v-btn>
          <v-btn
            small
            elevation="0"
            @click="setLanguage('en-US')"
            :color="getColor('en-US')"
          >
            <img src="@/assets/images/flags/en.png" />
          </v-btn>
        </div>
      </template>
    </tiptap-vuetify>
  </div>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  Blockquote,
  HardBreak,
  History,
  Paragraph,
  HorizontalRule,
  Code,
  OrderedList,
  ListItem,
  BulletList
} from 'tiptap-vuetify';

export default {
  components: { TiptapVuetify },
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },

    disabled: {
      type: Boolean,
      default: false
    },

    multiLang: {
      type: Boolean,
      required: false,
      default: false
    },

    currentLanguageKey: {
      type: String,
      required: false,
      default: () => 'de-DE'
    }
  },
  data: () => ({
    itemLanguageKey: 'de-DE',
    textInSelectedLanguage: '',
    extensions: [
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      History,
      Bold,
      Italic,
      Blockquote,
      Link,
      Underline,
      Strike,
      ListItem,
      BulletList,
      OrderedList,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ]
  }),

  watch: {
    textInSelectedLanguage(newValue) {
      if (!this.multiLang) {
        return;
      }

      var langItem = this.value.find(
        (x) => x.languageKey === this.itemLanguageKey
      );

      if (langItem) {
        langItem.translation = newValue;
      } else {
        this.value.push({
          languageKey: this.itemLanguageKey,
          translation: newValue
        });
      }
    },

    value: {
      handler() {
        this.updateField();
      },
      deep: true
    },

    currentLanguageKey() {
      this.updateField();
    }
  },

  mounted() {
    this.updateField();
    this.itemLanguageKey = this.currentLanguageKey;
  },

  methods: {
    setLanguage(lang) {
      this.itemLanguageKey = lang;
      this.updateField();
    },

    getColor(requiredColor) {
      if (this.itemLanguageKey === requiredColor) {
        return 'secondary';
      } else {
        return 'default';
      }
    },

    updateField() {
      if (this.multiLang) {
        const correctLanguage = this.value.find(
          (x) => x.languageKey === this.itemLanguageKey
        );

        this.textInSelectedLanguage = correctLanguage
          ? correctLanguage.translation
          : '';
      } else {
        this.textInSelectedLanguage = this.value;
      }
    }
  }
};
</script>

<style>
.tiptap-vuetify-editor--disabled .tiptap-vuetify-editor__toolbar {
  display: none;
}
.tiptap-vuetify-editor__content--disabled {
  color: inherit;
}
.tiptap-vuetify-editor--disabled {
  cursor: inherit;
}
</style>
