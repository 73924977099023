<template>
  <div v-if="item !== null">
    <v-row>
      <v-col cols="12">
        Art der Benachrichtigung:
        {{
          $t(`notificationTemplates.notificationType.${item.notificationType}`)
        }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        Aktionstyp
        {{
          $t(`notificationTemplates.notificationType.${item.notificationType}`)
        }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <locale-text-field :label="'Titel'" v-model="item.title" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <tip-tap :label="'Titel'" v-model="item.body" multi-lang />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn color="primary" @click="save">
          <v-icon class="mr-2">mdi-floppy</v-icon>
          {{ $t('save') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import localeTextField from '@/components/misc/localization-text-field';
import tipTap from '@/components/editor/TipTapMultiLang';

import { mapActions } from 'vuex';

export default {
  data: () => {
    return {
      item: null
    };
  },

  async mounted() {
    const templateId = this.$route.params.id;
    this.item = await this.getTemplate(templateId);
  },

  methods: {
    ...mapActions({
      getTemplate: 'notifications/getTemplate',
      saveTemplate: 'notifications/saveTemplate'
    }),

    save() {
      this.saveTemplate(this.item);
    }
  },

  components: {
    localeTextField,
    tipTap
  }
};
</script>
