var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"employees-overview-table",attrs:{"headers":_vm.headers,"items":_vm.localizedTemplates,"items-per-page":50,"loading":_vm.loading,"loading-text":_vm.$t('dataLoading'),"fixed-header":true},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.localizedTitle)+" ")]}},{key:"item.processType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getProcessType(item))+" ")]}},{key:"item.feedbackType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFeedbackType(item))+" ")]}},{key:"item.notificationType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("notificationTemplates.notificationType." + (item.notificationType))))+" ")]}},{key:"item.recipientUser",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("notificationTemplates.recipient." + (item.recipientUser))))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":"","to":{ name: 'EditNotificationTemplate', params: { id: item.id } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }