<template>
  <v-card class="segmented" flat>
    <v-card-title>
      <v-row class="align-center">
        <v-col cols="12">
          <h5>{{ $t('notificationTemplates.breadcrumb') }}</h5>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <template-form></template-form>
    </v-card-text>
  </v-card>
</template>

<script>
import templateForm from '@/components/NotificationTemplates/form.vue';

export default {
  components: {
    templateForm
  },

  data: () => ({}),

  async mounted() {}
};
</script>
